<template>
  <div>
    <el-row style="">
      <el-col :md="{span:20,offset:2}">
        <span style="display: none;">{{disabled}}</span>
        <el-tabs tab-position="left" v-model="currentTabsName" @tab-click="handleClick">
          <el-tab-pane name="comment">
            <span slot="label">
              评论
              <el-badge v-if="commentMessageTotal && commentMessageTotal>0"
              :value="commentMessageTotal" class="mark" :max="99"
              style="margin-left: -5px;position: absolute;z-index:99999"
              />
            </span>
            <MessageComment
             v-if="currentTabsName=='comment'"
             :dataList="dataList"
             :noMore="noMore"
             :disabled="disabled"
             :loading="loading"
             :commentMessageTotal="commentMessageTotal"
            ></MessageComment>
          </el-tab-pane>
          <el-tab-pane name="praiseStep">
            <span slot="label">
            赞踩
            <el-badge v-if="praiseStepMessageTotal&&praiseStepMessageTotal>0"
            :value="praiseStepMessageTotal" class="mark" :max="99"
            style="margin-left: -5px;position: absolute;"
            />
            </span>
            <MessagePraiseStep
             v-if="currentTabsName=='praiseStep'"
             :dataList="dataList"
             :noMore="noMore"
             :disabled="disabled"
             :loading="loading"
             :praiseStepMessageTotal="praiseStepMessageTotal"
            >
            </MessagePraiseStep>
          </el-tab-pane>
          <!-- <el-tab-pane name="chat" label="聊天">聊天</el-tab-pane> -->
          <el-tab-pane name="quiz">
            <span slot="label">
            提问
            <el-badge v-if="quizMessageTotal&&quizMessageTotal>0"
            :value="quizMessageTotal" class="mark" :max="99"
            style="margin-left: -5px;position: absolute;"
            />
            </span>
            <MessageQuiz
             v-if="currentTabsName=='quiz'"
             :dataList="dataList"
             :noMore="noMore"
             :disabled="disabled"
             :loading="loading"
             :quizMessageTotal="quizMessageTotal"
            >
            </MessageQuiz>
            </el-tab-pane>
            <el-tab-pane name="attention">
              <span slot="label">
              关注
              <el-badge v-if="attentionMeMessageTotal&&attentionMeMessageTotal>0"
              :value="attentionMeMessageTotal" class="mark" :max="99"
              style="margin-left: -5px;position: absolute;"
              />
              </span>
              <AttentionMe
               v-if="currentTabsName=='attention'"
               :dataList="dataList"
               :noMore="noMore"
               :disabled="disabled"
               :loading="loading"
              >
              </AttentionMe>
              </el-tab-pane>
              <el-tab-pane name="notice">
                <span slot="label">
                通知
                <el-badge v-if="noticeMessageTotal&&noticeMessageTotal>0"
                :value="noticeMessageTotal" class="mark" :max="99"
                style="margin-left: -5px;position: absolute;"
                />
                </span>
                <Notice></Notice>
                </el-tab-pane>
        </el-tabs>
        <div
        style="height: 450px;"
        >
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import global from '@/api/global_variable.js'
import {removeMessageApi} from '@/api/message'
import {getCommentedOfUserApi} from '@/api/comment'
import {getPraisedOfUserApi} from '@/api/praiseStep'
import {getCurrentByQuizApi} from '@/api/article'
import { mapGetters } from 'vuex'
import LittleBlogCard from './components/LittleBlogCard'
import MessageComment from './components/MessageComment'
import MessagePraiseStep from './components/MessagePraiseStep'
import MessageQuiz from './components/MessageQuiz'
import {getCurrentAttentionApi} from '@/api/relative'
import AttentionMe from './components/AttentionMe'
import Notice from './components/Notice'
export default{
   name:"message",
   components: {
     LittleBlogCard,
     MessageComment,
     MessagePraiseStep,
     MessageQuiz,
     AttentionMe,
     Notice
   },
   created(){
     this.restQuery()
     this.load()
     if(this.$route.query.username!=this.$store.state.user.user.username){
       this.$router.push({path: "/user", query: {username:this.$route.query.username}})
     }
   },
   data() {
     return {
       currentTabsName:"notice",
       dataList:[],
       totalPage:undefined,
       currentPage:undefined,
       totalNumber:undefined,
       loading:false,
       imgURL: global.imgURL,
       query:{
         pageNum:1,
         pageSize:8,
       },
     }
   },
   computed:{
     noMore() {
         return this.totalNumber < this.query.pageSize || this.totalPage===this.currentPage;
     },
     disabled() {
       this.$emit("changeDisabled",this.loading || this.noMore)
       return this.loading || this.noMore
     },
     ...mapGetters([
       'commentMessageTotal',
       'praiseStepMessageTotal',
       'quizMessageTotal',
       'messageTotal',
       'attentionMeMessageTotal',
       'noticeMessageTotal'
     ])
   },
   methods: {
     async getCurrentByQuiz(){
       await getCurrentByQuizApi(this.query).then((res)=>{
          if(res.data.success && this.currentTabsName=='quiz'){
            this.totalNumber=res.data.data.list.length
            for(var i =0;i<this.totalNumber;++i){
              this.dataList.push(res.data.data.list[i])
            }
            this.query.pageNum++;
            this.totalPage=res.data.data.pages
            this.currentPage=res.data.data.pageNum
          }
          this.loading=false;
       })
     },
     async getPraisedOfUser(){
       await getPraisedOfUserApi(this.query).then((res)=>{
         if(res.data.success && this.currentTabsName=='praiseStep'){
           this.totalNumber=res.data.data.list.length
           for(var i =0;i<this.totalNumber;++i){
             this.dataList.push(res.data.data.list[i])
           }
           this.query.pageNum++;
           this.totalPage=res.data.data.pages
           this.currentPage=res.data.data.pageNum
         }
         this.loading=false;
       })

     },
     link_to_blog(path,blogId,event){
       this.$router.push({path: path, query: {blogId:blogId}})
     },
     link_to_user(path,username){
        this.$emit("restUser",username)
        this.$router.push({path: path, query: {username:username}})
     },
     load() {
         this.loading=true
         switch(this.currentTabsName){
           case "comment":
           this.getCommentedOfUser()
           break;
          case "praiseStep":
            this.getPraisedOfUser()
            break
          case "quiz":
            this.getCurrentByQuiz()
            break;
          case "attention":
            this.getCurrentAttention()
            break
         }
     },
     async getCurrentAttention(){
        await getCurrentAttentionApi(this.query).then((res)=>{
          if(res.data.success && this.currentTabsName=='attention'){
              this.totalNumber=res.data.data.list.length
              for(var i =0;i<this.totalNumber;++i){
                this.dataList.push(res.data.data.list[i])
              }
              this.query.pageNum++;
                this.totalPage=res.data.data.pages
                this.currentPage=res.data.data.pageNum
          }
          this.loading=false;
        })
     },
    async getCommentedOfUser(){
       await getCommentedOfUserApi(this.query).then((res)=>{
         if(res.data.success && this.currentTabsName=='comment'){
           this.totalNumber=res.data.data.list.length
           for(var i =0;i<this.totalNumber;++i){
             this.dataList.push(res.data.data.list[i])
           }
           this.query.pageNum++;
           this.totalPage=res.data.data.pages
           this.currentPage=res.data.data.pageNum
         }
         this.loading=false;
       })

     },
     removeMessage() {
       switch(this.currentTabsName){
         case 'comment':
          this.$store.dispatch('message/commentTotalClear')
          break
        case 'quiz':
          this.$store.dispatch('message/quizTotalClear')
          break;
        case 'praiseStep':
          this.$store.dispatch('message/praiseStepTotalClear')
          break;
        case 'attention':
          this.$store.dispatch('message/attentionMeClear')
          break;
       }
       if(this.currentTabsName!='notice'){
          removeMessageApi({username:this.$store.state.user.user.username,type:this.currentTabsName})
       }
     },
     restQuery(){
       this.query={
         pageNum:1,
         pageSize:8,
       }
        this.dataList=[],        this.totalPage=undefined
        this.currentPage=undefined
        this.totalNumber=undefined
     },
      handleClick(tab, event) {
        this.removeMessage()
        this.restQuery()
        this.load()
     }
   },
}
</script>

<style>
</style>
