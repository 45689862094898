<template>
  <div>
    <div style="padding: 20px;">
      <template v-for="(item,index) in dataList">
        <el-row>
          <el-col
          :md="{span:1}" :xs="5"
          @click.stop="link_to_user('/user',item.username)"
          style="cursor: pointer;"
          >
            <el-avatar
                size="large"
                :src="item.avatar"

              ></el-avatar>
          </el-col>
          <el-col :md="18" :xs="19">
            <div
            @click.stop="link_to_user('/user',item.username)"
            style="cursor: pointer;"
            >
              <h4 style="margin: 0px;height: 44px;line-height: 44px;">{{
                item.nickname
              }}
              <span style="color: #D46A8E;">关注了你</span>
              <span
              style="
              font-size: 13px;color: #8D8D8D;
              margin-left: 20px;
              "
              > {{ formatTime(new Date(item.createTime).getTime(), "{y}年{m}月{d}日") }}
              </span>
              </h4>

            </div>
          </el-col>
          <el-col :md="{span:5,offset:0}" style=" height: 44px;line-height: 44px;">
              <Attention style="width: 100px;"
              :attentionedUser="item.username"
              :attentionUser="username"
              :attentionedNickname="nickname"
              ></Attention>
          </el-col>
        </el-row>
        <el-divider></el-divider>
      </template>
    </div>
    <div style="height: 100px;float:left;width: 100%;">
      <span style="display: none;">{{disabled}}</span>
      <h4 v-if="noMore && !loading"style="color:#BABE99;text-align: center;">没有更多了哦！</h4>
      <div v-loading="loading" style="margin-top: 20px;">
      </div>
    </div>
  </div>
</template>

<script>
  import global from '@/api/global_variable.js'
  import Attention from './Attention'
  import { mapGetters } from 'vuex'
  import { parseTime,formatTime } from '@/utils/date'
  export default{
    name:'AttentionMe',
    props:['disabled','loading','noMore','dataList'],
    components: {
      Attention
    },
    computed: {
      ...mapGetters([
        'username',
        'nickname'
      ])
    },
    data() {
      return {
      }
    },
    methods:{
      formatTime,
      parseTime,
      link_to_blog(path,blogId,event){
        this.$router.push({path: path, query: {blogId:blogId}})
      },
      link_to_user(path,username){
         this.$emit("restUser",username)
         this.$router.push({path: path, query: {username:username}})
      },
    }
  }
</script>

<style>
</style>
