import request from '@/utils/request'
export const getArticelApi = (query) => request.get('/public/blog/selectByPageAndConditionOfReception', {params:query})
export const getArticelByIdApi = (data) => request.get('/public/blog/getBlogById', { params: data })
export const getAllTypeApi = (data) => request.get('/public/type/getAllType')
export const getAllLabelApi = (data) => request.get('/public/label/getAllLabel')
export const publishArticleApi = (data) => request.post('/blog/create', data,{useToken:true})
export const updateArticleApi = (data) => request.post('/blog/update', data,{useToken:true})
export const deleteArticleApi = (data) => request.post('/blog/delete', data,{useToken:true})
export const uploadImageApi = (data) => request.post('/upload/uploadImage', data)
export const getDetailArticelByIdApi = (data) => request.get('/blog/getDetailBlogById', { params: data,useToken:true})
export const selectDraftsByPageAndConditionApi = (query) => request.get('/blog/selectDraftsByPageAndCondition', { params: query,useToken:true})
export const createSolveApi = (data) => request.post('/blog/createSolve', data,{useToken:true})
export function getHotBlogApi(){
  return request({
    url:'/blog/getHotBlog',
    method:'get'
  })
}
export function getHotQuizApi(){
  return request({
    url:'/blog/getHotQuiz',
    method:'get'
  })
}
export function getCurrentByQuizApi(query){
  return request({
    url:'/blog/getCurrentByQuiz',
    method:'get',
    params:query,
    useToken:true
  })
}
