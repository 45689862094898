<template>
  <div>
    <div style="padding: 20px;">
      <template v-for="(item,index) in dataList">
        <el-row>
          <el-col :md="14">
            <h4
            v-if="item.creator=='general'"
            >
            <span style="color: orangered;">
              游客
            </span>
            向您发送了一个提问，请您及时帮忙解决哦！！
            <span
            style="
            font-size: 13px;color: #8D8D8D;
            margin-left: 20px;
            "
            > {{formatTime(new Date(item.createTime).getTime(),'{y}年{m}月{d}日')}}</span>
            </h4>
            <h4 v-if="item.creator!='general'&&item.user">
              用户
              <span style="color: orangered;">
                {{item.user.nickname}}
              </span>
              向您发送了一个提问，请您及时帮忙解决哦！！
              <span
              style="
              font-size: 13px;color: #8D8D8D;
              margin-left: 20px;
              "
              > {{formatTime(new Date(item.createTime).getTime(),'{y}年{m}月{d}日')}}</span>
            </h4>
          </el-col>
          <el-col :md="{span:5,offset:2}" :xs="24">
              <h4
               v-if="item.isContain>0"
              style="color: #0892F8;"
              >我已处理</h4>
              <h4
               v-if="item.isContain==0"
              style="color: #FF226B;"
              >尚未处理</h4>
          </el-col>
          <el-col :md="3" :xs="24">
              <LittleBlogCard :blog="item">
              </LittleBlogCard>
          </el-col>
        </el-row>
        <el-divider></el-divider>
      </template>
    </div>
    <div style="height: 100px;float:left;width: 100%;">
      <span style="display: none;">{{disabled}}</span>
      <h4 v-if="noMore && !loading"style="color:#BABE99;text-align: center;">没有更多了哦！</h4>
      <div v-loading="loading" style="margin-top: 20px;">
      </div>
    </div>
  </div>
</template>

<script>
  import LittleBlogCard from './LittleBlogCard'
  import global from '@/api/global_variable.js'
  import { parseTime,formatTime } from '@/utils/date'
  export default{
    name:'MessageQuiz',
    props:['disabled','loading','noMore','dataList'],
    components: {
      LittleBlogCard
    },
    data() {
      return {
        imgURL: global.imgURL
      }
    },
    methods:{
      formatTime,
      link_to_blog(path,blogId,event){
        this.$router.push({path: path, query: {blogId:blogId}})
      },
      link_to_user(path,username){
         this.$emit("restUser",username)
         this.$router.push({path: path, query: {username:username}})
      },
    }
  }
</script>

<style>
</style>
