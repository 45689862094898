<template>
  <div>

    <div style="padding: 20px;">
      <div v-for="item in dataList"
      >
      <el-row>
        <el-col
        :md="{span:1}" :xs="5"
        >
          <el-avatar
              size="large"
              :src="item.user.avatar"
              @click.stop="link_to_user('/user',item.creator)"
            ></el-avatar>
        </el-col>
        <el-col :md="item.commentObj!=null?{span:15}:{span:20}" :xs="19">
          <div
          @click.stop="link_to_user('/user',item.creator)"
          style="cursor: pointer;"
          >
            <h4 style="margin: 0px;">{{
              item.user.nickname
            }}
            <span
            style="
            font-size: 13px;color: #8D8D8D;
            margin-left: 20px;
            "
            > {{formatTime(new Date(item.createTime).getTime(),'{y}年{m}月{d}日')}}</span>
            </h4>
            <p
            style="font-size: 14px;"
            >
              {{item.comment}}
            </p>
          </div>
        </el-col>
        <el-col :md="5"
        v-if="item.commentObj!=null"
        >
          <h4
            class="card-heading"
          >我的评论</h4>
          <p class="card-synopsis">{{item.commentObj.comment}}</p>
        </el-col>
        <el-col :md="{span:3}" :xs="24">
          <LittleBlogCard :blog="item.blog!=null?item.blog:item.commentObj!=null?item.commentObj.blog:null">
          </LittleBlogCard>
        </el-col>
      </el-row>
        <el-divider></el-divider>
      </div>
    </div>
    <div style="height: 100px;float:left;width: 100%;">
      <span style="display: none;">{{disabled}}</span>
      <h4 v-if="noMore && !loading"style="color:#BABE99;text-align: center;">没有更多了哦！</h4>
      <div v-loading="loading" style="margin-top: 20px;">
      </div>
    </div>
  </div>
</template>

<script>
  import LittleBlogCard from './LittleBlogCard'
  import global from '@/api/global_variable.js'
  import { parseTime,formatTime } from '@/utils/date'
  export default{
    name:'MessageComment',
    props:['disabled','loading','noMore','dataList','commentMessageTotal'],
    components: {
      LittleBlogCard
    },
    data() {
      return {
        imgURL: global.imgURL
      }
    },
    methods:{
      parseTime,
      formatTime,
      link_to_blog(path,blogId,event){
        this.$router.push({path: path, query: {blogId:blogId}})
      },
      link_to_user(path,username){
         this.$emit("restUser",username)
         this.$router.push({path: path, query: {username:username}})
      },
    }
  }
</script>

<style scoped>
  .card-heading{
    overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
     margin:10px 0px 0px 0px
  }
  .card-synopsis{
    overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
    font-size: 14px;
  }
</style>
