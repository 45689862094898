<template>
  <div>
      <div style="height: 100px;float:left;width: 100%;" v-if="noticeMessageList.length<=0">
        <!-- <span style="display: none;">{{disabled}}</span> -->
        <h4 style="color:#BABE99;text-align: center;">暂无通知</h4>
        <!-- <div v-loading="loading" style="margin-top: 20px;"> -->
        <!-- </div> -->
      </div>
      <el-row v-for="item in noticeMessageList" style="padding: 20px;">
        <el-col
        :md="{span:1}" :xs="5"
        style="cursor: pointer;"
        >
        <div
        v-if="item.type!='friendshipLink'"
        @click.stop="link_to_user('/user',item.sender.username)"
        >
          <el-avatar
              size="large"
              :src="item.sender.avatar"
            ></el-avatar>
        </div>
         <a
         :href="item.message.friendshipLink.link"
         v-if="item.type=='friendshipLink'"
         style="cursor: pointer;text-decoration: none;"
         >
           <el-avatar
               size="large"
               :src="item.sender.avatar"
             ></el-avatar>
         </a>
        </el-col>
        <el-col :md="15" :xs="19">
          <div
          >
          <a
          :href="item.message.friendshipLink.link"
          v-if="item.type=='friendshipLink'"
          style="cursor: pointer;text-decoration: none;
          color: #000000;
          "
          >
            <h4 style="margin: 0px;">{{
              item.sender.nickname
            }}
            <span
            style="
            font-size: 13px;color: #8D8D8D;
            margin-left: 20px;
            "
            > {{ formatTime(new Date(item.createTime).getTime(), "{y}年{m}月{d}日") }}</span>
            </h4>
            </a>
            <div
            @click.stop="link_to_user('/user',item.sender.username)"
            v-if="item.type!='friendshipLink'"
            style="cursor: pointer;"
            >
              <h4 style="margin: 0px;">{{
                item.sender.nickname
              }}
              <span
              style="
              font-size: 13px;color: #8D8D8D;
              margin-left: 20px;
              "
              > {{ formatTime(new Date(item.createTime).getTime(), "{y}年{m}月{d}日") }}</span>
              </h4>
              </div>
            <p
            v-if="item.type!='friendshipLink'"
            style="font-size: 14px;"
            >
              {{item.message}}
            </p>
            <p
            v-if="item.type=='friendshipLink'"
            style="font-size: 14px;"
            >
              {{item.message.message}}
            </p>
          </div>
        </el-col>
        <el-col :md="{span:3,offset:5}">
            <button
            style="cursor: pointer;"
            class="button"
            v-if="item.type!='friendshipLink'"
            @click="removeMessage(item.id,item.receiver.username)"
            :disabled="loadingObj[item.id]"
            >
            <LoadingButton :size="15" v-if="loadingObj[item.id]"></LoadingButton>
              <span v-if="!loadingObj[item.id]"
              class="button-content"
              >我知道了</span>
            </button>
            <button
            style="cursor: pointer;"
            class="button"
            v-if="item.type=='friendshipLink'"
            @click="friendshipLink(item.message.friendshipLink,item.sender,item.id,item.receiver.username)"
            >
            <LoadingButton :size="15" v-if="loadingObj[item.id]"></LoadingButton>
            <span
            class="button-content"
            v-if="!loadingObj[item.id]">给他通过</span>
            </button>
        </el-col>
      </el-row>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import {removeMessageByIdAndUsernameApi} from '@/api/message'
  import { parseTime,formatTime } from '@/utils/date'
  import {friendshipLinkApi} from '@/api/friendship_link'
  export default{
    name:'Notice',
    created(){

    },
    data() {
      return {
        loadingObj: {}
      }
    },
    computed: {
      ...mapGetters([
        'noticeMessageList'
      ])
    },
    methods: {
      formatTime,
      link_to_user(path,username){
         this.$emit("restUser",username)
         this.$router.push({path: path, query: {username:username}})
      },
      parseTime(date,form){
        return parseTime(date,form)
      },
      friendshipLink(link,sender,messageId,receiver){
        this.$set(this.loadingObj,messageId,true)
        friendshipLinkApi(link.id,sender.username).then((res)=>{
          if(res.data.success){
            this.$message({
              type:'success',
              message:'您通过了'+link.name+'申请'
            })
            removeMessageByIdAndUsernameApi(messageId,receiver).then((res)=>{
              if(res.data.success){
                 this.$store.dispatch('message/noticeClear',messageId)
              }
            })
          }else{
            this.$message({
              type:'warning',
              message:res.data.errorMsg
            })
          }
          this.$set(this.loadingObj,messageId,false)
        })
      },
      removeMessage(messageId,username){
        this.$set(this.loadingObj,messageId,true)
        removeMessageByIdAndUsernameApi(messageId,username).then((res)=>{
          if(res.data.success){
             this.$store.dispatch('message/noticeClear',messageId)
          }
          this.$set(this.loadingObj,messageId,false)
        })
      }
    }
  }
</script>

<style scoped>
  .button {
    position: relative;
    overflow: hidden;
    height: 3rem;
    padding: 0 2rem;
    border-radius: 1.5rem;
    background: #3d3a4e;
    background-size: 400%;
    color: #fff;
  }

  .button:hover::before {
    transform: scaleX(1);
  }

  .button-content {
    position: relative;
    z-index: 1;
  }

  .button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    background: linear-gradient(
      82.3deg,
      rgba(150, 93, 233, 1) 10.8%,
      rgba(99, 88, 238, 1) 94.3%
    );
    transition: all 0.475s;
  }
</style>
