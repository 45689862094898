import request from '@/utils/request'

export function createFriendshipApi(data){
  return request({
    url:'/friendshipLink/create',
    method:'post',
    data,
    useIpAddress:true
  })
}
export function friendshipLinkApi(linkId,ipv4){
  return request({
    url:'/friendshipLink/pass',
    method:'post',
    params:{linkId,ipv4},
    useToken:true
  })
}
